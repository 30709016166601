import { Card, CardBody, CardHeader } from "@nextui-org/card";
import { Banknote } from "lucide-react";
import { IconClock, IconCrown } from "@tabler/icons-react";
const cards = [
  {
    title: "Highest Earnings",
    description:
      "Compared to other ad networks, we have very user-friendly PopUps, and we are number one when it comes to payment rates. Our PopUps are safe and free of viruses, which, unfortunately, happens on other networks a lot.",
    icon: <Banknote className="h-6 w-6  text-primary" />,
  },
  {
    title: "Exclusivity",
    description:
      "We are a very exclusive network that only accepts large websites, enabling us to provide the best support.",
    icon: <IconCrown className="h-6 w-6  text-primary" />,
  },
  {
    title: "Great Payout Options",
    description:
      "We offer fast payouts on the 1st of every month (NET+1) or even payouts every two weeks! We have several payout options like Bank, PayPal, and any cryptocurrency you can think of!",
    icon: <IconClock className="h-6 w-6  text-primary" />,
  },
];

export default function CardGrid() {
  return (
    <div className="grid gap-8 grid-cols-3 max-w-[900px] ">
      {cards.map((card, index) => (
        <Card key={index} className="p-3 " x-chunk="dashboard-01-chunk-0">
          <CardHeader className="block">
            <div className="p-2 inline-block bg-primary bg-opacity-10 rounded-full">
              {card.icon}
            </div>
            <h1 className="text-xl mt-2 font-bold ">{card.title}</h1>
          </CardHeader>
          <CardBody className="mt-0 mb-0 text-sm text-muted-foreground ">
            {card.description}
          </CardBody>
        </Card>
      ))}
    </div>
  );
}
