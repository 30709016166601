import { Image } from "@nextui-org/react";

export default function MakeMoney() {
  return (
    <div className="flex items-center justify-center w-full bg-primary bg-opacity-5">
      <div className="flex items-center justify-center max-w-[900px]">
        <div className="flex  items-center justify-center gap-4">
          <Image height={300} src="/money.png" width={300} />
          <div className="flex flex-col items-start w-[400px] justify-center gap-4">
            <h1 className="text-4xl font-bold">
              Make Money <br /> With Your Hobby
            </h1>
            <p className="text-sm  text-muted-foreground">
              It has never been easier, what are you waiting for?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
