import { Image } from "@nextui-org/image";

import { subtitle } from "@/components/primitives";
import DefaultLayout from "@/layouts/default";
import CardGrid from "@/components/landing/CardGrid";
import MakeMoney from "@/components/landing/MakeMoney";
import StatsShowoff from "@/components/landing/StatsShowoff";

export default function IndexPage() {
  return (
    <DefaultLayout>
      <div className=" relative flex  w-full items-center justify-center bg-primary mb-10">
        <section className="relative max-w-[32rem]  w-full  flex flex-col items-center justify-center pb-9">
          <div className="inline-block max-w-lg text-center justify-center ">
            <Image height={400} src="/logo-large.png" />
            {/*<h1 className={title({ color: "violet" })}>beautiful&nbsp;</h1>*/}
            {/*<br />*/}
            {/* <h1 className={title()}>
             websites regardless of your design experience.
            </h1> */}
            <h4 className={subtitle({ class: " text-white" })}>
              The best and most exclusive Network focusing on PopUps.
            </h4>
          </div>
        </section>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-y-10">
        <CardGrid />
        <StatsShowoff />
        <MakeMoney />
      </div>
    </DefaultLayout>
  );
}
