import NumberTicker from "../magicui/NumberTicker";

const numbers = [
  {
    value: 2833202,
    text: "Paid out in Total",
    sign: "$",
  },
  {
    value: 8089,
    text: "Daily Revenue",
    sign: "$",
  },
  {
    value: 36,
    text: "Registered Users",
    sign: "",
  },
];

function StatsShowoff() {
  return (
    <div className="flex items-center justify-center w-full py-4">
      <div className="grid grid-cols-3  items-center justify-around gap-4  w-[900px]">
        {numbers.map((item, ix) => (
          <div key={ix} className="flex flex-col items-center  justify-center ">
            <div className="flex flex-col items-center justify-center  gap-2">
              <h1 className="text-xl font-bold">
                <NumberTicker value={item.value} />
                {item.sign}
              </h1>
              <p className="text-lg  text-muted-foreground">{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatsShowoff;
